import type { CompetitionHit } from '/~/types/api/algolia/competitions'
import { createDate } from '/~/utils/format/date'

function replace(value: string) {
  return value
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
}

export class Competition {
  raw: CompetitionHit
  id: string
  title: string
  image: string
  intro: string
  terms: string
  formFields: CompetitionHit['config']['fields']['formFields']
  campaignName: string
  startDate: string
  endDate: string

  constructor(raw: CompetitionHit) {
    this.raw = raw

    const data = this.raw.config?.fields ?? {}

    this.id = this.raw.slug
    this.title = data.title
    this.image = data.image
    this.intro = replace(data.intro ?? '')
    this.terms = replace(data.terms ?? '')
    this.formFields = data.formFields ?? []
    this.campaignName = data.campaignName
    this.startDate = data.startDate
    this.endDate = data.endDate
  }

  get isActive() {
    const today = createDate()
    const startDate = this.startDate ? createDate(this.startDate) : today
    const endDate = this.endDate ? createDate(this.endDate) : today

    return startDate.isSameOrBefore(today) && endDate.isSameOrAfter(today)
  }
}
